<template>
	<div>
		<Header :title="title" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch" style="margin-top: 20px;">
				<a-row>
					<a-form-item class="ui-form__item" label="买家信息" name="userInfo">
						<a-input v-model:value="formState.userInfo" placeholder="请输入关键字搜索"></a-input>
					</a-form-item>

					<a-form-item v-if="type === 1" class="ui-form__item" label="商品名称" name="title">
						<a-input v-model:value="formState.title" placeholder="请输入商品名称"></a-input>
					</a-form-item>

					<a-form-item v-if="type === 2" class="ui-form__item" label="卖品名称" name="title">
						<a-input v-model:value="formState.title" placeholder="请输入卖品名称"></a-input>
					</a-form-item>

					<a-form-item v-if="type === 3" class="ui-form__item" label="影票信息" name="filmName">
						<a-input v-model:value="formState.filmName" placeholder="请输入影票信息"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="订单编号" name="orderNo">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单编号"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="佣金来源" name="level">
						<a-select v-model:value="formState.level" style="width: 190px;" placeholder="请选择">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">自购返佣</a-select-option>
							<a-select-option :value="2">邀请人返佣</a-select-option>
							<a-select-option :value="2">团队长返佣</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="下单时间">
						<a-range-picker show-time v-model:value="orderTime"></a-range-picker>
					</a-form-item>

					<a-form-item class="ui-form__item" label="返佣发放时间">
						<a-range-picker show-time v-model:value="commissionTime"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 1800 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'info'">
							<div>会员昵称：{{ record.nickname }}</div>
							<div>会员手机号：{{ record.phone }}</div>
						</template>
						<template v-if="column.key === 'num'">
							{{ record.cinemaSeatsJsonVOS.length || 0 }}
						</template>
						<template v-if="column.key === 'showTime'">
							<div>{{ transDateTime(record.showStartTime) }}</div>
							至
							<div>{{ transDateTime(record.showEndTime) }}</div>
						</template>
						<template v-if="column.key === 'goods'">
							<div v-if="type === 1">
								<div style="display: flex;" v-for="item in record.oiList" :key="item.id">
									<a-image style="width: 60px;" :src="item.picUrl ? item.picUrl.split(',')[0] : ''">
									</a-image>
									<div style="margin-left: 10px;">
										{{ item.mainTitle }}
									</div>
								</div>
							</div>
							<div v-else-if="type === 2">
								<div class="ui-goods" v-for="item in record.oiList" :key="item.id">
									<div style="display: flex;align-items: flex-start;">
										<a-image style="width: 50px;border-radius: 4px;"
											:src="item.imgUrl"></a-image>
										<div style="margin-left: 8px;">
											<div style="font-size: 12px;">
												{{ item.mainTitle }}
											</div>
											<div style="margin-top: 2px;font-size: 14px;color: orange;">
												￥{{ item.price }}
												<span style="margin-left: 60px;color: #999;">x{{ item.num }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
						<template v-if="column.key === 'price'">
							<div v-for="(item, i) in record.oiList" :key="item.id">
								<div v-if="i > 0" style="margin: 10px 0;border-bottom: dashed 1px #999;"></div>
								<div>销售价：{{ item.price }}元</div>
								<div>市场价：{{ item.originPrice }}元</div>
								<div>成本价：{{ item.costPrice }}元</div>
							</div>
						</template>
						<template v-if="column.key === 'orderPrice'">
							<div v-if="type !== 3">订单总额：{{ record.totalFee }}元</div>
							<div v-if="type !== 3">快递：{{ record.deliveryFee }}元</div>
							<div>实付金额：{{ record.payment }}元</div>
						</template>
						<template v-if="column.key === 'orderInfo'">
							<div>订单编号：{{ record.orderNo }}</div>
							<div>支付编号：{{ record.outTradeNo }}</div>
							<div>下单时间：{{ transDateTime(record.createTime) }}</div>
						</template>
						<template v-if="column.key === 'type'">
							<div v-for="item in record.commissionRecord" :key="item.id">
								<div v-if="item.level !== 4">
									<div>
										<div style="color: rgb(112, 182, 3);" v-if="item.level === 1">
											自购返佣：{{ item.commission }}元</div>
										<div style="color: rgb(132, 0, 255);" v-if="item.level === 2">
											邀请人返佣：{{ item.commission }}元</div>
										<div style="color: rgb(252, 169, 89);" v-if="item.level === 3">
											店长返佣：{{ item.commission }}元</div>
									</div>
								</div>
							</div>
						</template>
						<template v-if="column.key === 'time'">
							<div v-for="item in record.commissionRecord" :key="item.id">
								<div v-if="item.level !== 4 && item.finishTime">
									<span :style="{ color: ['rgb(112, 182, 3)', 'rgb(132, 0, 255)', 'rgb(252, 169, 89)'][item.level - 1] }">{{ transDateTime(item.finishTime) }}</span>
								</div>
							</div>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import {
		getDistributorOrderList
	} from '@/service/modules/distribution.js';
	export default {
		components: {
			Icon,
			Header
		},
		props: {
			id: {
				type: Number,
				default: 0
			},
			type: { // 1:商品分销订单  2:卖品分销订单  3:现金影票分销订单
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				title: '',
				loading: false,
				formState: {
					level: 0
				},
				orderTime: [],
				commissionTime: [],
				searchData: {},
				showModal: false,
				list: [],
				columns: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			if (this.type === 1) {
				this.title = '商品分销订单明细';
			}
			if (this.type === 2) {
				this.title = '卖品分销订单明细';
			}
			if (this.type === 3) {
				this.title = '现金影票分销订单明细';
			}
			this.columns = this.transColums();
			this.onSearch();
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.level = this.searchData.level ? this.searchData.level : undefined;
				if (this.orderTime && this.orderTime.length) {
					this.searchData.orderStartTime = parseInt(this.orderTime[0].valueOf() / 1000);
					this.searchData.orderEndTime = parseInt(this.orderTime[1].valueOf() / 1000);
				}
				if (this.commissionTime && this.commissionTime.length) {
					this.searchData.commissionStartTime = parseInt(this.commissionTime[0].valueOf() / 1000);
					this.searchData.commissionEndTime = parseInt(this.commissionTime[1].valueOf() / 1000);
				}
				this.getData();
			},
			reset() {
				this.orderTime = [];
				this.commissionTime = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			getData() {
				this.getGoodsData();
			},
			async getGoodsData() {
				this.loading = true;
				try {
					let ret = await getDistributorOrderList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						userId: this.id,
						saleType: this.type,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			transColums() {
				if (this.type === 1) {
					return [{
						title: '买家信息',
						key: 'info'
					}, {
						title: '商品信息',
						key: 'goods',
						width: 400
					}, {
						title: '商品单价',
						key: 'price'
					}, {
						title: '订单交易总额',
						key: 'orderPrice'
					}, {
						title: '订单信息',
						key: 'orderInfo'
					}, {
						title: '返佣信息',
						key: 'type'
					}, {
						title: '返佣发放时间',
						key: 'time'
					}];
				}
				if (this.type === 2) {
					return [{
						title: '买家信息',
						key: 'info'
					}, {
						title: '卖品信息',
						key: 'goods',
						width: 400
					}, {
						title: '订单交易总额',
						key: 'orderPrice'
					}, {
						title: '订单信息',
						key: 'orderInfo'
					}, {
						title: '返佣信息',
						key: 'type'
					}, {
						title: '返佣发放时间',
						key: 'time'
					}];
				}
				if (this.type === 3) {
					return [{
						title: '买家信息',
						key: 'info'
					}, {
						title: '订单信息',
						key: 'orderInfo'
					}, {
						title: '影片名称',
						dataIndex: 'filmName'
					}, {
						title: '数量',
						key: 'num'
					}, {
						title: '订单交易总额',
						key: 'orderPrice'
					}, {
						title: '放映时间',
						key: 'showTime'
					}, {
						title: '返佣信息',
						key: 'type'
					}, {
						title: '返佣发放时间',
						key: 'time'
					}];
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
	.ui-goods {
			padding: 6px 10px;
			border-radius: 10px;
			border: solid 1px #EEE;
		}
		.ui-goods + .ui-goods {
			margin-top: 4px;
		}
</style>

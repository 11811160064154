<template>
	<div v-show="(!showDetail && !showOrderList && !showInviteList)">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="会员信息" name="memberInfo">
						<a-input v-model:value="formState.memberInfo" placeholder="请输入会员昵称\会员手机号"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="分销等级" name="distributionId">
						<a-select v-model:value="formState.distributionId" style="width: 190px;" placeholder="请选择分销等级">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in levelList" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="状态" name="isDistributionDisabled">
						<a-select v-model:value="formState.isDistributionDisabled" style="width: 190px;" placeholder="请选择分销等级">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>			
			
			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'distributionTime'">
							{{ transDateTime(record.distributionTime)}}
						</template>
						<template v-if="column.key === 'earnings'">
							<div>已到账：{{ record.money }}</div>
							<div>总收益：{{ record.incomes }}</div>
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDistributionDisabled ? '禁用' : '启用' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['member_distributor_list_detail']" @click="onDetail(record)">
											<a-menu-item >
												会员详情
											</a-menu-item>
										</div>
										<div v-permission="['member_distributor_list_order']" @click="onOrder(record, 1)">
											<a-menu-item>
												商品分销订单明细
											</a-menu-item>
										</div>
										<div v-permission="['member_distributor_list_snack']" @click="onOrder(record, 2)">
											<a-menu-item>
												卖品分销订单明细
											</a-menu-item>
										</div>
										<div v-permission="['member_distributor_list_ticket']" @click="onOrder(record, 3)">
											<a-menu-item>
												现金购票分销订单明细
											</a-menu-item>
										</div>
										<div v-permission="['member_distributor_list_invite']" @click="onInvite(record)">
											<a-menu-item>
												查看下级
											</a-menu-item>
										</div>
										<div @click="onUser(record)">
											<a-menu-item>
												修改推荐人
											</a-menu-item>
										</div>
										<div @click="onLevel(record)">
											<a-menu-item>
												修改分销等级
											</a-menu-item>
										</div>
										<div v-permission="['member_distributor_list_open']" v-if="record.isDistributionDisabled === 1" @click="onDisabled(record)">
											<a-menu-item>启用用户分销身份</a-menu-item>
										</div>
										<div v-permission="['member_distributor_list_disabled']" v-else @click="onDisabled(record)">
											<a-menu-item>禁用用户分销身份</a-menu-item>
										</div>
										<div v-permission="['member_distributor_list_level']" @click="updateDistribution({id: 0}, record)">
											<a-menu-item>删除分销商</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			
			<a-modal v-model:visible="showModal" destroyOnClose title="修改推荐人" width="800px">
				<template #footer>
					<a-button type="primary" @click="showModal = false">关闭</a-button>
				</template>
				<userList :id="id" :recommendId="recommendId" :recommendInfo="recommendInfo" @onRef="getData"></userList>
			</a-modal>
			
			<a-modal v-model:visible="showLevelModal" destroyOnClose width="750px" title="修改分销等级">
				<template #footer>
					<a-button @click="showLevelModal = false;">关闭</a-button>
				</template>
				<h3>分销商当前等级：{{ editData.distributionTitle }}</h3>
				<a-table :columns="levelColumns" :dataSource="levelList" :pagination="false">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'action'">
							<a-button v-permission="['member_distributor_list_level']" type="link" :disabled="editData.distributionId === record.id" @click="updateDistribution(record)">修改为该分销等级</a-button>
						</template>
					</template>
				</a-table>
			</a-modal>
		</a-spin>
	</div>
	<detail v-if="showDetail" :id="id" @back="onBack"></detail>
	<orderList v-else-if="showOrderList" :id="id" :type="type" @back="onBack"></orderList>
	<inviteList v-else-if="showInviteList" :id="id" @back="onBack"></inviteList>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import detail from '@/views/member/memberManage/info/temp.vue';
	import orderList from './orderList.vue';
	import inviteList from './inviteList.vue';
	import userList from './userList.vue';
	import { getDistributorList, getDistributionLevelList, updateDistribution, pullOnShelves, pullOffShelves  } from '@/service/modules/distribution.js';
	export default {
		components: { Icon, detail, orderList, inviteList, userList },
		data() {
			return {
				loading: false,
				formState: {
					isDistributionDisabled: '',
					distributionId: 0
				},
				searchData: {},
				showModal: false,
				modelRef: {
					name: '',
					link: ''
				},
				list: [],
				columns: [{ 
					title: '会员昵称',
					dataIndex: 'nickname'
				}, {
					title: '会员手机号',
					dataIndex: 'phone'
				}, {
					title: '分销等级',
					dataIndex: 'distributionTitle'
				},{
					title: '成为分销商时间',
					key: 'distributionTime'
				}, {
					title: '购买金额',
					dataIndex: 'payment'
				}, {
					title: '收益',
					key: 'earnings'
				}, {
					title: '状态',
					key: 'isDisabled'
				}, {
					title: '操作',
					key: 'action'
				},
				],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				type: 0,
				id: 0,
				recommendId: 0,
				showDetail: false,
				showOrderList: false,
				showInviteList: false,
				showLevelModal: false,
				editData: {},
				levelList: [],
				levelColumns: [{
					title: '等级名称',
					dataIndex: 'title'
				}, {
					title: '操作',
					key: 'action'
				}],
				recommendInfo: {}
			}
		},
		created() {
			this.getDistributionLevelList();
			this.getData();
		},
		methods: {
			onBack(isRef) {
				this.showDetail = false;
				this.showOrderList = false;
				this.showInviteList = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.distributionId = this.searchData.distributionId ? this.searchData.distributionId : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try{
					let ret = await getDistributorList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getDistributionLevelList() {
				let ret = await getDistributionLevelList({
					page: 1,
					pageSize: 999
				});
				if (ret.code === 200) {
					this.levelList = ret.data.list;
				}
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确定' + (item.isDistributionDisabled ? '启用' : '禁用') + '该用户分销身份吗？',
					onOk: async () => {
						this.loading = true;
						let ret;
						try {
							if (item.isDistributionDisabled) {
								ret = await pullOnShelves({
									id: item.id
								});
							} else {
								ret = await pullOffShelves({
									id: item.id
								});
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDistributionDisabled ? '启用' : '禁用') + '用户分销身份成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onAdd() {
				this.showModal = true;
				this.$nextTick(() => {
					this.$refs.addForm.resetFields();
				});
			},
			onSubmit() {
				this.$refs.addForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let ret = await saveOrganization(this.modelRef);
						this.loading = false;
						if (ret.code === 200) {
							this.$refs.addForm.resetFields();
							this.showModal = false;
							this.getData();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			onDetail(item) {
				this.showDetail = true;
				this.id = item.id;
			},
			onOrder(item, type) {
				this.showOrderList = true;
				this.type = type;
				this.id = item.id;
			},
			onInvite(item) {
				this.showInviteList = true;
				this.id = item.id;
				this.recommendId = item.recommendId;
			},
			onUser(item) {
				this.showModal = true;
				this.recommendId = item.recommendId;
				this.recommendInfo = {
					recommendNickname: item.recommendNickname || '',
					recommendAvatar: item.recommendAvatar || '',
					recommendPhone: item.recommendPhone || ''
				}
				this.id = item.id;
			},
			onLevel(item) {
				this.showLevelModal = true;
				this.id = item.id;
				this.editData = JSON.parse(JSON.stringify(item));
			},
			updateDistribution(level, item) {
				this.$confirm({
					title: '提示',
					content: item ? '确定删除该用户的分销等级吗？' : '确定修改该用户的分销等级吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await updateDistribution({
								userId: item ? item.id : this.id,
								distributionId: level.id
							});
							this.loading = false;
							if (ret.code === 200) {
								if (level.id === 0) {
									this.$message.success('删除成功');
								} else {
									this.$message.success('修改成功');
									this.editData.distributionId = level.id;
								}
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
